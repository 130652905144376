import React from "react";
import { useHistory } from "react-router-dom";
import { useMenuContext } from "../../Context/MenuContext";
import useWindowDimensions from "../../Utils/WindowSize";
import "./sidebar.scss";
import StorySuboptions from "../StorySubOptions/Suboptions";
import VentureSubOptions from "../VentureSubOptions/VentureSubOptions";
import downarrow from "../../Image/downarrow.svg";
import uparrow from "../../Image/uparrow.svg";
import SPSubOptions from "../SPSubOptions/SPSubOptions";

const Sidebar = () => {
  const {
    storySelectedOpt,
    setStorySelectedOpt,
    ventureSelectedOpt,
    setVentureSelectedOpt,
    spSelectedOpt,
    setSpSelectedOpt,
    gxselectedBtn,
    setGxselectedBtn,
    setMarketverseSelectedBtn,
    menu1,
    setMenu1,
    menu2,
    setMenu2,
    menu3,
    setMenu3,
    menu4,
    setMenu4,
    active,
    setActive,
    mobileSidebar,
    setMobileSidebar,
  } = useMenuContext();

  const { width } = useWindowDimensions();
  const history = useHistory();

  return (
    <div
      className="left-div"
      style={{ display: width > 900 ? "flex" : "none" }}
    >
      <div
        className="logo-div"
        onClick={() => {
          history.push("/");
          setMenu1(false);
          setMenu2(false);
          setMenu3(false);
          setMenu4(false);
          setActive(false);
          setStorySelectedOpt("upbringing");
          setVentureSelectedOpt("");
          setGxselectedBtn("brands");
          setSpSelectedOpt("speeches");
          setMarketverseSelectedBtn("protocols");
        }}
      ></div>
      <div className="options-div">
        <div
          className="options"
          style={{
            fontWeight: menu1 === true ? "700" : "400",
            display: (menu1 && active) || (!menu1 && !active) ? "flex" : "none",
            marginTop: menu1 === true ? "5%" : "0%",
          }}
        >
          <div
            className="box-div"
            onClick={() => {
              setMenu1(!menu1);
              setMenu2(false);
              setMenu3(false);
              setMenu4(false);
              setActive(!active);
              if (!menu1) {
                history.push("/story");
              } else {
                history.push("/");
                setStorySelectedOpt("upbringing");
              }
            }}
          >
            <div className="anime">Story</div>
            <img
              src={menu1 === true ? uparrow : downarrow}
              alt=""
              style={{ width: width > 1500 ? "15px" : "12px" }}
            />
          </div>
        </div>
        <div
          style={{
            display: active && menu1 === true ? "flex" : "none",
          }}
          className={`margin-class ${!menu1 ? "hidingclass" : ""}`}
        >
          <StorySuboptions />
        </div>

        <div
          className="options"
          style={{
            fontWeight: menu2 === true ? "700" : "400",
            display: (menu2 && active) || (!menu2 && !active) ? "flex" : "none",
            marginTop:
              menu2 === true && width > 1100 && width < 1500 ? "20%" : "0",
          }}
        >
          <div
            className="box-div"
            onClick={() => {
              setMenu2(!menu2);
              setMenu1(false);
              setMenu3(false);
              setMenu4(false);
              setActive(!active);
              if (!menu2) {
                history.push("/ventures");
              } else {
                history.push("/");
                setVentureSelectedOpt("");
                setGxselectedBtn("brands");
              }
            }}
          >
            <div className="anime">Ventures</div>
            <img
              src={menu2 === true ? uparrow : downarrow}
              alt=""
              style={{ width: width > 1500 ? "15px" : "12px" }}
            />
          </div>
        </div>
        <div
          style={{
            display: active && menu2 === true ? "flex" : "none",
          }}
          className={`margin-class ${!menu2 ? "hidingclass" : ""}`}
        >
          <VentureSubOptions />
        </div>

        <div
          className="options"
          style={{
            fontWeight: menu3 === true ? "700" : "400",
            display: (menu3 && active) || (!menu3 && !active) ? "flex" : "none",
            marginTop: menu3 && width > 900 && width < 1600 ? "5%" : "0%",
          }}
        >
          <div
            className="box-div"
            onClick={() => {
              setMenu3(!menu3);
              setMenu1(false);
              setMenu2(false);
              setMenu4(false);
              setActive(!active);
              if (!menu3) {
                history.push("/spcontent");
              } else {
                history.push("/");
              }
            }}
          >
            <div className="anime">SP Content</div>
            <img
              src={menu3 === true ? uparrow : downarrow}
              alt=""
              style={{ width: width > 1500 ? "15px" : "12px" }}
            />
          </div>
        </div>
        <div
          style={{
            display: active && menu3 === true ? "flex" : "none",
          }}
          className={`margin-class ${!menu3 ? "hidingclass" : ""}`}
        >
          <SPSubOptions />
        </div>

        <div
          className="options"
          style={{
            fontWeight: menu4 === true ? "700" : "400",
            display: active ? "none" : "flex",
            // display: (menu4 && active) || (!menu4 && !active) ? "flex" : "none",
          }}
        >
          <div
            className="box-div"
            onClick={() => {
              setMenu4(!menu4);
              setMenu1(false);
              setMenu3(false);
              setMenu2(false);
              // setActive(!active);
            }}
          >
            <div className="anime">Consulting</div>
            <img
              src={menu4 === true ? uparrow : downarrow}
              alt=""
              style={{ width: width > 1500 ? "15px" : "12px" }}
            />
          </div>
        </div>

        <div className="options" style={{ display: active ? "none" : "flex" }}>
          <div style={{ gap: "0px" }} className="box-div">
            <div className="anime">Contact</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
