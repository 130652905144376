import React from "react";
import "../HomePage/homepage.scss";
import nvest from "../../Image/nvest.svg";
import gx from "../../Image/gxlogo.svg";
import markets from "../../Image/markets.svg";
import viralgroup from "../../Image/viralgroup-logo.svg";
import inr from "../../Image/inr-logo.svg";
import employment from "../../Image/employment.svg";
import entertainment from "../../Image/entertainment.svg";
// import cryptostream from "../../Image/cryptostream.svg";
import business from "../../Image/business.svg";
import cryptostartups from "../../Image/cryptostartups.svg";
import spendinggroup from "../../Image/spendinggroup.svg";

import { useMenuContext } from "../../Context/MenuContext";
import useWindowDimensions from "../../Utils/WindowSize";
import GX from "./GX";
import Viralgroup from "./Viralgroup";
import Inrgroup from "./Inrgroup";
import Employmentgroup from "./Employmentgroup";
import Cryptostartups from "./Cryptostartups";
import Spendinggroup from "./Spendinggroup";
import Businesses from "./Businesses";
import Sidebar from "../Sidebar/Sidebar";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import MobileNavbar from "../MobileNavbar";
import Marketsverse from "./Marketsverse";

const Ventures = () => {
  const {
    ventureSelectedOpt,
    setVentureSelectedOpt,
    gxselectedBtn,
    setGxselectedBtn,
    setMenu2,
    setActive,
  } = useMenuContext();
  const { width } = useWindowDimensions();

  useEffect(() => {
    setMenu2(true);
    setActive(true);
    if (window.location.pathname === "/ventures") {
      setVentureSelectedOpt("");
    } else if (window.location.pathname === "/ventures/gx") {
      setVentureSelectedOpt("gx");
    } else if (window.location.pathname === "/ventures/marketsverse") {
      setVentureSelectedOpt("marketsverse");
    } else if (window.location.pathname === "/ventures/viral") {
      setVentureSelectedOpt("viral");
    } else if (window.location.pathname === "/ventures/inr") {
      setVentureSelectedOpt("inr");
    } else if (window.location.pathname === "/ventures/employment") {
      setVentureSelectedOpt("employment");
    } else if (window.location.pathname === "/ventures/spending") {
      setVentureSelectedOpt("spending");
    } else if (window.location.pathname === "/ventures/businesses") {
      setVentureSelectedOpt("businesses");
    } else if (window.location.pathname === "/ventures/cryptostartups") {
      setVentureSelectedOpt("cryptostartups");
    }
  }, [window.location.pathname]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: width <= 900 ? "column" : "row",
        width: "100vw",
        height: "100vh",
        overflowX: "hidden",
      }}
    >
      <Sidebar />
      <MobileNavbar />
      <div className="venture-container" id="preventscroll">
        {ventureSelectedOpt === "gx" ? (
          <GX />
        ) : ventureSelectedOpt === "marketsverse" ? (
          <Marketsverse />
        ) : ventureSelectedOpt === "viral" ? (
          <Viralgroup />
        ) : ventureSelectedOpt === "inr" ? (
          <Inrgroup />
        ) : ventureSelectedOpt === "employment" ? (
          <Employmentgroup />
        ) : ventureSelectedOpt === "cryptostartups" ? (
          <Cryptostartups />
        ) : ventureSelectedOpt === "spending" ? (
          <Spendinggroup />
        ) : ventureSelectedOpt === "businesses" ? (
          <Businesses />
        ) : (
          <>
            <div className="header">
              <img src={nvest} alt="" />
            </div>
            <div className="companies-div">
              <Link
                className="ind-div"
                style={{
                  textDecorationLine: "none",
                }}
                onClick={() => {
                  setVentureSelectedOpt("gx");
                }}
                to="/ventures/gx"
              >
                <div className="hover-class"></div>
                <img src={gx} alt="" /> Global X Change
              </Link>

              <Link
                className="ind-div"
                style={{
                  textDecorationLine: "none",
                }}
                onClick={() => {
                  setVentureSelectedOpt("marketsverse");
                }}
                to="/ventures/marketsverse"
              >
                <div className="hover-class"></div>
                <img src={markets} alt="" /> MarketsVerse
              </Link>

              <Link
                className="ind-div"
                style={{
                  textDecorationLine: "none",
                }}
                onClick={() => setVentureSelectedOpt("viral")}
                to="/ventures/viral"
              >
                <div className="hover-class"></div>
                <img src={viralgroup} alt="" /> Viral Group
              </Link>

              <Link
                className="ind-div"
                style={{
                  textDecorationLine: "none",
                }}
                onClick={() => setVentureSelectedOpt("inr")}
                to="/ventures/inr"
              >
                <div className="hover-class"></div>
                <img src={inr} alt="" /> INR Group
              </Link>

              <Link
                className="ind-div"
                style={{
                  textDecorationLine: "none",
                }}
                onClick={() => setVentureSelectedOpt("employment")}
                to="/ventures/employment"
              >
                <div className="hover-class"></div>
                <img src={employment} alt="" /> Employment Group
              </Link>

              <Link
                className="ind-div"
                style={{
                  textDecorationLine: "none",
                }}
                onClick={() => setVentureSelectedOpt("cryptostartups")}
                to="/ventures/cryptostartups"
              >
                <div className="hover-class"></div>
                <img src={cryptostartups} alt="" /> CryptoStartups
              </Link>

              <Link
                className="ind-div"
                style={{
                  textDecorationLine: "none",
                }}
                onClick={() => setVentureSelectedOpt("spending")}
                to="/ventures/spending"
              >
                <div className="hover-class"></div>
                <img src={spendinggroup} alt="" /> Spending Group
              </Link>

              <div
                className="ind-div"
                style={{
                  cursor: "not-allowed",
                }}
                // onClick={() => setVentureSelectedOpt("businesses")}
                // to="/ventures/business"
              >
                {/* <div className="hover-class"></div> */}
                <img src={business} alt="" /> Businesses
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Ventures;
