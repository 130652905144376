import React from "react";
import { useEffect } from "react";
import { useMenuContext } from "../../Context/MenuContext";
import useWindowDimensions from "../../Utils/WindowSize";
import MobileNavbar from "../MobileNavbar";
import Sidebar from "../Sidebar/Sidebar";
import Carousel from "./Carousel/Carousel";
import ListenersCarousel from "./Carousel/ListenersCarousel";
import "./spcontent.scss";

const SPContent = () => {
  const { setMenu3, setActive, spSelectedOpt, setSpSelectedOpt } =
    useMenuContext();
  const { width } = useWindowDimensions();

  useEffect(() => {
    setMenu3(true);
    setActive(true);
    if (window.location.pathname === "/spcontent") {
      setSpSelectedOpt("speeches");
    }
  }, [window.location.pathname]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: width <= 900 ? "column" : "row",
        width: "100vw",
        height: "100vh",
        overflowX: "hidden",
      }}
    >
      <Sidebar />
      <MobileNavbar />
      <div className="sp-container">
        <div className="div-container">
          <div className="div1">
            <div className="bottom-div">Business</div>
          </div>
          <div className="div2">
            <div className="bottom-div">Crypto</div>
          </div>
          <div className="div3">
            <div className="bottom-div">History</div>
          </div>
          <div className="div4">
            <div className="bottom-div">Neuro</div>
          </div>
        </div>
        <div className="image-container">
          <div className="div-box">
            <div className="image-1">
              <img
                src="https://jayshetty.me/wp-content/uploads/2021/11/quiz-banner.png"
                alt=""
              />
            </div>
            <div className="text-box">
              <div>TRUE PURPOSE IS CLOSER THAN YOU REALIZE.</div>
              <button>TAKE THE QUIZ</button>
            </div>
          </div>
        </div>
        <div className="subscribe-section">
          <div style={{ opacity: "0.5" }}>SUBSCRIBE ON</div>
          <div className="btns">APPLE</div>
          <div className="btns">SPOTIFY</div>
          <div className="btns">pandora</div>
          <div className="btns">STITCHER</div>
          <div className="btns">SiriusXM</div>
          <div className="btns">amazon music</div>
        </div>
        <div className="content-section">
          <div className="about">
            <div className="pic"></div>
            <div className="about-txt">
              <h3 style={{ textTransform: "uppercase", fontWeight: "600" }}>
                About On Purpose Podcast
              </h3>
              <p style={{ lineHeight: "1.6", marginTop: "2%" }}>
                Jay Shetty’s purpose is to make wisdom go viral. On Purpose
                podcast brings fascinating conversations with some of the most
                insightful people in the world straight to viewers all over the
                world. Listen to new episodes on Mondays and Fridays anywhere
                you get your podcasts. Live life today On Purpose!
              </p>
            </div>
          </div>
          <div className="section-title">
            <h3 style={{ fontSize: "1.4rem" }}>Latest Episodes</h3>
          </div>
          <div className="carousel-section">
            <Carousel />
          </div>
        </div>
        <div className="content-section">
          <div className="section-title">
            <h3 style={{ fontSize: "1.4rem" }}>On Work</h3>
          </div>
          <div className="carousel-section">
            <Carousel />
          </div>
        </div>
        <div className="testimonial-section">
          <div className="txt">What listeners are saying</div>
          <div className="carousel-section">
            <ListenersCarousel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SPContent;
