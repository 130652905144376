import React from "react";
import { useHistory } from "react-router-dom";
import { useMenuContext } from "../../Context/MenuContext";
import { Preventscroll } from "../utils/PreventScroll";

const VentureSubOptions = () => {
  const {
    ventureSelectedOpt,
    setVentureSelectedOpt,
    setGxselectedBtn,
    setMarketverseSelectedBtn,
    setInrSelectedBtn,
  } = useMenuContext();
  const history = useHistory();

  return (
    <div className="sub-options">
      <div
        className="sub-opt"
        onClick={() => {
          setVentureSelectedOpt("gx");
          // Preventscroll();
          setMarketverseSelectedBtn("protocols");
          setInrSelectedBtn("holdings");
          history.push("/ventures/gx");
        }}
        style={{
          fontWeight: ventureSelectedOpt === "gx" ? "700" : "400",
          background: ventureSelectedOpt === "gx" ? "#ffffff" : "",
          borderRadius: ventureSelectedOpt === "gx" ? "15px" : "",
          color: ventureSelectedOpt === "gx" ? "#182443" : "#ffffff",
        }}
      >
        GX
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setVentureSelectedOpt("marketsverse");
          // Preventscroll();
          setGxselectedBtn("brands");
          setInrSelectedBtn("holdings");
          history.push("/ventures/marketsverse");
        }}
        style={{
          fontWeight: ventureSelectedOpt === "marketsverse" ? "700" : "400",
          background: ventureSelectedOpt === "marketsverse" ? "#ffffff" : "",
          borderRadius: ventureSelectedOpt === "marketsverse" ? "15px" : "",
          color: ventureSelectedOpt === "marketsverse" ? "#182443" : "#ffffff",
        }}
      >
        MarketsVerse
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setVentureSelectedOpt("viral");
          setGxselectedBtn("brands");
          setMarketverseSelectedBtn("protocols");
          setInrSelectedBtn("holdings");
          // Preventscroll();
          history.push("/ventures/viral");
        }}
        style={{
          fontWeight: ventureSelectedOpt === "viral" ? "700" : "400",
          background: ventureSelectedOpt === "viral" ? "#ffffff" : "",
          borderRadius: ventureSelectedOpt === "viral" ? "15px" : "",
          color: ventureSelectedOpt === "viral" ? "#182443" : "#ffffff",
        }}
      >
        Viral
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setVentureSelectedOpt("inr");
          setGxselectedBtn("brands");
          setMarketverseSelectedBtn("protocols");
          history.push("/ventures/inr");
        }}
        style={{
          fontWeight: ventureSelectedOpt === "inr" ? "700" : "400",
          background: ventureSelectedOpt === "inr" ? "#ffffff" : "",
          borderRadius: ventureSelectedOpt === "inr" ? "15px" : "",
          color: ventureSelectedOpt === "inr" ? "#182443" : "#ffffff",
        }}
      >
        INR
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setVentureSelectedOpt("employment");
          setGxselectedBtn("brands");
          setMarketverseSelectedBtn("protocols");
          setInrSelectedBtn("holdings");
          history.push("/ventures/employment");
        }}
        style={{
          fontWeight: ventureSelectedOpt === "employment" ? "700" : "400",
          background: ventureSelectedOpt === "employment" ? "#ffffff" : "",
          borderRadius: ventureSelectedOpt === "employment" ? "15px" : "",
          color: ventureSelectedOpt === "employment" ? "#182443" : "#ffffff",
        }}
      >
        Employment
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setVentureSelectedOpt("cryptostartups");
          setGxselectedBtn("brands");
          setMarketverseSelectedBtn("protocols");
          setInrSelectedBtn("holdings");
          history.push("/ventures/cryptostartups");
        }}
        style={{
          fontWeight: ventureSelectedOpt === "cryptostartups" ? "700" : "400",
          background: ventureSelectedOpt === "cryptostartups" ? "#ffffff" : "",
          borderRadius: ventureSelectedOpt === "cryptostartups" ? "15px" : "",
          color:
            ventureSelectedOpt === "cryptostartups" ? "#182443" : "#ffffff",
        }}
      >
        CryptoStartups
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setVentureSelectedOpt("spending");
          setGxselectedBtn("brands");
          setMarketverseSelectedBtn("protocols");
          setInrSelectedBtn("holdings");
          history.push("/ventures/spending");
        }}
        style={{
          fontWeight: ventureSelectedOpt === "spending" ? "700" : "400",
          background: ventureSelectedOpt === "spending" ? "#ffffff" : "",
          borderRadius: ventureSelectedOpt === "spending" ? "15px" : "",
          color: ventureSelectedOpt === "spending" ? "#182443" : "#ffffff",
        }}
      >
        Spending
      </div>
      <div
        className="sub-opt"
        // onClick={() => {
        //   setVentureSelectedOpt("businesses");
        //   setGxselectedBtn("brands");
        //   setMarketverseSelectedBtn("protocols");
        //   setInrSelectedBtn("holdings");
        //   history.push("/ventures/business");
        // }}
        style={{
          fontWeight: ventureSelectedOpt === "businesses" ? "700" : "400",
          background: ventureSelectedOpt === "businesses" ? "#ffffff" : "",
          borderRadius: ventureSelectedOpt === "businesses" ? "15px" : "",
          color: ventureSelectedOpt === "businesses" ? "#182443" : "#ffffff",
          cursor: "not-allowed",
        }}
      >
        Businesses
      </div>
    </div>
  );
};

export default VentureSubOptions;
