import React, { useEffect } from "react";
import { useMenuContext } from "../../Context/MenuContext";
import sideimage from "../../Image/b1.svg";
import useWindowDimensions from "../../Utils/WindowSize";
import MobileNavbar from "../MobileNavbar";
import Sidebar from "../Sidebar/Sidebar";
import downarrow from "../../Image/downarrow.svg";
import uparrow from "../../Image/uparrow.svg";
import { useHistory } from "react-router-dom";
import StorySuboptions from "../StorySubOptions/Suboptions";

const Story = () => {
  const {
    storySelectedOpt,
    setStorySelectedOpt,
    setMenu1,
    setActive,
    active,
    menu1,
    menu2,
    menu3,
    menu4,
    mobileSidebar,
    setMobileSidebar,
    setMenu4,
    setMenu2,
    setMenu3,
    setVentureSelectedOpt,
    setGxselectedBtn,
    VentureSubOptions,
  } = useMenuContext();

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    setMenu1(true);
    setActive(true);
    if (window.location.pathname === "/story/upbringing") {
      setStorySelectedOpt("upbringing");
    } else if (window.location.pathname === "/story/precrypto") {
      setStorySelectedOpt("precrypto");
    } else if (window.location.pathname === "/story/nvestera") {
      setStorySelectedOpt("nvestera");
    } else if (window.location.pathname === "/story/vision") {
      setStorySelectedOpt("vision");
    } else if (window.location.pathname === "/story/philanthropy") {
      setStorySelectedOpt("philanthropy");
    }
  }, [window.location.pathname]);

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        flexDirection: width <= 900 ? "column" : "row",
        position: "relative",
        overflowX: "hidden",
      }}
    >
      <Sidebar />
      <MobileNavbar />
      <div className="story-container">
        <div className="stories-div">
          <div className="story-text">Shorupan’s Story</div>
          <div
            style={{
              fontSize: "1.25rem",
              height: "5%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {storySelectedOpt === "upbringing"
              ? "My Upbringing | 1996 - 2017"
              : storySelectedOpt === "precrypto"
              ? "Pre Crypto | 2015 - 2017"
              : storySelectedOpt === "nvestera"
              ? "Nvest Era | 2017 - Present"
              : storySelectedOpt === "vision"
              ? "Vision | Present - Infinity"
              : "Philanthropy | 2022 - Present"}
          </div>
          <div className="story-content">
            <div>
              Shorupan is a sophisticated blockchain investor and a sought after
              subject matter specialist for enterprise-level blockchain
              integration. With a background in structured portfolio management,
              Shorupan has consulted and scaled traditional financial firms
              whose specializations range from the foreign exchange markets to
              insurance derivatives.
            </div>
            <br />
            <div>
              His exposure to the cryptocurrency markets came during his tenure
              as Chief Investment Officer at Toronto based VC firm, Generation Z
              International. There he constructed one of Canada's first
              blockchain investment funds which monetized off the market boom of
              2016-2017. Since then Shorupan has become a thought leader in the
              space, engaging with corporations and universities to help
              elucidate the business implications of the blockchain.
            </div>
            <br />
            <div>
              In 2018 he Co-Founded Nvest Global Enterprises and launched
              Canada's first retail cryptocurrency financial institution. Now he
              is scaling NGE to become a global provider of blockchain based
              banking systems and manages operations in over six countries.
            </div>
          </div>
        </div>
        <div className="side-img">
          <img src={sideimage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Story;
