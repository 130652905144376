import React, { createContext, useContext, useState } from "react";

export const MenuContext = createContext({});

export const MenuContextProvider = ({ children }) => {
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);
  const [menu3, setMenu3] = useState(false);
  const [menu4, setMenu4] = useState(false);
  const [active, setActive] = useState(false);
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [storySelectedOpt, setStorySelectedOpt] = useState("upbringing");
  const [ventureSelectedOpt, setVentureSelectedOpt] = useState("");
  const [spSelectedOpt, setSpSelectedOpt] = useState("speeches");
  const [gxselectedBtn, setGxselectedBtn] = useState("brands");
  const [marketverseSelectedBtn, setMarketverseSelectedBtn] =
    useState("protocols");
  const [viralSelectedBtn, setViralSelectedBtn] = useState("brands");
  const [inrSelectedBtn, setInrSelectedBtn] = useState("holdings");
  const [empSelectedBtn, setEmpSelectedBtn] = useState("brands");
  const [cryptoSelectedBtn, setCryptoSelectedBtn] = useState("brands");
  const [spendSelectedBtn, setSpendSelectedBtn] = useState("brands");
  const [businessSelectedBtn, setBusinessSelectedBtn] = useState("brands");
  const [mobileMenu, setMobileMenu] = useState("story");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <MenuContext.Provider
      value={{
        menu1,
        setMenu1,
        menu2,
        setMenu2,
        menu3,
        setMenu3,
        menu4,
        setMenu4,
        active,
        setActive,
        mobileSidebar,
        setMobileSidebar,
        storySelectedOpt,
        setStorySelectedOpt,
        ventureSelectedOpt,
        setVentureSelectedOpt,
        spSelectedOpt,
        setSpSelectedOpt,
        gxselectedBtn,
        setGxselectedBtn,
        marketverseSelectedBtn,
        setMarketverseSelectedBtn,
        viralSelectedBtn,
        setViralSelectedBtn,
        inrSelectedBtn,
        setInrSelectedBtn,
        empSelectedBtn,
        setEmpSelectedBtn,
        cryptoSelectedBtn,
        setCryptoSelectedBtn,
        spendSelectedBtn,
        setSpendSelectedBtn,
        businessSelectedBtn,
        setBusinessSelectedBtn,
        mobileMenu,
        setMobileMenu,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext);
