import React from "react";
import spendingfull from "../../Image/spendingfull.svg";
import arrow from "../../Image/clickArrow.svg";
import { useMenuContext } from "../../Context/MenuContext";
import useWindowDimensions from "../../Utils/WindowSize";
import { useState } from "react";
import { useEffect } from "react";
import { GetData } from "./helper";
import Skeleton from "react-loading-skeleton";

const Spendinggroup = () => {
  const { spendSelectedBtn, setSpendSelectedBtn, isLoading, setIsLoading } =
    useMenuContext();
  const { width } = useWindowDimensions();
  const [spendingMenu, setSpendingMenu] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    let obj = "spendinggroup";

    GetData(obj).then((response) => {
      let result = response?.data?.brands;
      setSpendingMenu(result);
      setIsLoading(false);
    });
  }, [spendSelectedBtn]);

  return (
    <>
      <div className="header">
        <img src={spendingfull} alt="" />
      </div>
      <div className="brand-btns">
        <div
          className="each-btn"
          style={{
            background: spendSelectedBtn === "brands" ? "#F3F4F5" : "",
            fontWeight: spendSelectedBtn === "brands" ? "700" : "500",
          }}
          onClick={() => setSpendSelectedBtn("brands")}
        >
          Brands
        </div>
      </div>
      <div className="companies-div1">
        {isLoading && spendSelectedBtn === "brands"
          ? Array(10)
              .fill(" ")
              .map((item, index) => {
                return (
                  <div
                    className="ind-div"
                    style={{ borderRight: "0.5px solid #efefef" }}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton width={40} height={40} borderRadius={50} />
                      <Skeleton
                        width={150}
                        height={25}
                        style={{ marginLeft: 5 }}
                      />
                    </div>
                  </div>
                );
              })
          : !isLoading &&
            spendingMenu?.map((e, i) => {
              return (
                <div
                  className="ind-div"
                  style={{ borderRight: "0.5px solid #efefef" }}
                  key={e.name}
                >
                  <div
                    style={{ display: "flex", gap: "15px" }}
                    className="margintop"
                  >
                    <img src={e.colored_icon} alt="" /> {e.name}
                  </div>
                  <div className="hover-show">
                    <div style={{ fontWeight: "500" }}>{`${
                      e?.description !== null
                        ? e?.description
                        // ?.substring(0, 65)
                        : ""
                    }`}</div>
                    <div
                      className="zoom2"
                      onClick={() => window.open(e.website)}
                    >
                      <div className="zoom1" style={{ cursor: "pointer" }}>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            height: "40px",
                          }}
                        >
                          <div className="check1-text">CHECK</div>
                          <img className="clickarrow-img" src={arrow} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
};

export default Spendinggroup;
