import React from "react";
import gxfull from "../../Image/gx-full.svg";
import mcb from "../../Image/mcb.svg";
import markets from "../../Image/markets.svg";
import retired from "../../Image/retired.svg";
import affiliate from "../../Image/affiliate.svg";
import instacrypto from "../../Image/instacrypto.svg";
import ccswealth from "../../Image/ccswealth.svg";
import toplevelcrypto from "../../Image/toplevel.svg";
import assets from "../../Image/assets.svg";
import tokenoptions from "../../Image/tokenoptions.svg";
import tokenswap from "../../Image/tokenswap.svg";
import moneymarkets from "../../Image/moneymarkets.svg";
import defirouting from "../../Image/defirouting.svg";
import terminals from "../../Image/terminals.svg";
import otcdesks from "../../Image/otcdesks.svg";
import nftreward from "../../Image/nftreward.svg";
import signals from "../../Image/signals.svg";
import arrow from "../../Image/clickArrow.svg";
import { useMenuContext } from "../../Context/MenuContext";
import useWindowDimensions from "../../Utils/WindowSize";

const GX = () => {
  const {
    ventureSelectedOpt,
    setVentureSelectedOpt,
    gxselectedBtn,
    setGxselectedBtn,
  } = useMenuContext();

  const { width } = useWindowDimensions();

  const brandmenu = [
    {
      icon: retired,
      name: "Retired App",
      text: "Whitelabel Platform For Cryptocurrency Startups",
      link: "http://retired.app/",
    },
    {
      icon: affiliate,
      name: "Affiliate App",
      text: "Social finance ecosystem for users to get paid from their network",
      link: "https://affiliate.app/",
    },
    {
      icon: instacrypto,
      name: "InstaCrypto",
      text: "A global P2P marketplace which connects users with OTCDesks",
      link: "https://instacrypto.com/",
    },
    {
      icon: ccswealth,
      name: "CCSWealth",
      text: "A wealth management platform offering fixed income bonds.",
      link: "https://ccswealth.com/",
    },
    {
      icon: toplevelcrypto,
      name: "TopLevelCrypto",
      text: "An asset management platform operating the world’s first IFO.",
      link: "https://toplevelcrypto.com/",
    },
    {
      icon: assets,
      name: "Assets.io",
      text: "A portfolio management tool providing access to all Marketsverse offerings",
      link: "https://assets.io/",
    },
    {
      icon: mcb,
      name: "MyCryptoBrand",
      text: "Whitelabel platform for cryptocurrency startups",
      link: "https://mycryptobrand.com/",
    },
    {
      icon: tokenoptions,
      name: "TokenOptions",
      text: "A futures trading platform for derivatives of tokenized assets",
      link: "https://tokenoptions.com/",
    },
    {
      icon: tokenswap,
      name: "TokenSwap",
      text: "A non custodial cryptocurrency exchange and liquidity provider.",
      link: "https://tokenswap.io/",
    },
  ];

  const protocolmenu = [
    {
      icon: moneymarkets,
      name: "MoneyMarkets",
    },
    {
      icon: defirouting,
      name: "DefiRouting",
    },
  ];

  const mcbmenu = [
    {
      icon: terminals,
      name: "Terminals",
    },
    {
      icon: otcdesks,
      name: "OTCDesks",
    },
    {
      icon: nftreward,
      name: "NFTReward",
    },
    {
      icon: signals,
      name: "Signals",
    },
  ];

  return (
    <>
      <div className="header">
        <img src={gxfull} alt="" />
      </div>
      <div className="brand-btns">
        <div
          className="each-btn"
          style={{
            background: gxselectedBtn === "brands" ? "#F3F4F5" : "",
            fontWeight: gxselectedBtn === "brands" ? "700" : "500",
          }}
          onClick={() => setGxselectedBtn("brands")}
        >
          Brands
        </div>
        <div
          className="each-btn"
          style={{
            background: gxselectedBtn === "protocols" ? "#F3F4F5" : "",
            fontWeight: gxselectedBtn === "protocols" ? "700" : "500",
          }}
          onClick={() => setGxselectedBtn("protocols")}
        >
          Protocols
        </div>
        <div
          className="each-btn"
          style={{
            background: gxselectedBtn === "mcb" ? "#F3F4F5" : "",
            fontWeight: gxselectedBtn === "mcb" ? "700" : "500",
          }}
          onClick={() => setGxselectedBtn("mcb")}
        >
          MCB Templates
        </div>
      </div>
      <div className="companies-div1">
        {gxselectedBtn === "brands"
          ? brandmenu.map((e, i) => {
              return (
                <div
                  className="ind-div"
                  style={{ borderRight: "0.5px solid #efefef" }}
                  key={e.name}
                >
                  <div
                    style={{ display: "flex", gap: "15px" }}
                    className="margintop"
                  >
                    <img src={e.icon} alt="" /> {e.name}
                  </div>
                  <div className="hover-show">
                    <div style={{ fontWeight: "500" }}>{e.text}</div>
                    <div className="zoom2" onClick={() => window.open(e.link)}>
                      <div className="zoom1" style={{ cursor: "pointer" }}>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            height: "40px",
                          }}
                        >
                          <div className="check1-text">CHECK</div>
                          <img className="clickarrow-img" src={arrow} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : gxselectedBtn === "protocols"
          ? protocolmenu.map((e, i) => {
              return (
                <div
                  className="ind-div"
                  style={{ borderRight: "0.5px solid #efefef" }}
                  key={e.name}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      alignItems: "center",
                    }}
                    // className="margintop"
                  >
                    <img src={e.icon} alt="" /> {e.name}
                  </div>
                </div>
              );
            })
          : mcbmenu.map((e, i) => {
              return (
                <div
                  className="ind-div"
                  style={{ borderRight: "0.5px solid #efefef" }}
                  key={e.name}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      alignItems: "center",
                    }}
                    // className="margintop"
                  >
                    <img src={e.icon} alt="" /> {e.name}
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
};

export default GX;
