import React from "react";
import { useHistory } from "react-router-dom";
import { useMenuContext } from "../../Context/MenuContext";

const SPSubOptions = () => {
  const { spSelectedOpt, setSpSelectedOpt } = useMenuContext();
  const history = useHistory();

  return (
    <div
      className="sub-options"
      // style={{ height: "475px" }}
    >
      <div
        className="sub-opt"
        onClick={() => {
          setSpSelectedOpt("speeches");
          history.push("/spcontent/speeches");
        }}
        style={{
          fontWeight: spSelectedOpt === "speeches" ? "700" : "400",
          background: spSelectedOpt === "speeches" ? "#344676" : "",
          borderRadius: spSelectedOpt === "speeches" ? "15px" : "",
        }}
      >
        Speeches
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setSpSelectedOpt("shows");
          history.push("/spcontent/shows");
        }}
        style={{
          fontWeight: spSelectedOpt === "shows" ? "700" : "400",
          background: spSelectedOpt === "shows" ? "#344676" : "",
          borderRadius: spSelectedOpt === "shows" ? "15px" : "",
        }}
      >
        Shows
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setSpSelectedOpt("books");
          history.push("/spcontent/books");
        }}
        style={{
          fontWeight: spSelectedOpt === "books" ? "700" : "400",
          background: spSelectedOpt === "books" ? "#344676" : "",
          borderRadius: spSelectedOpt === "books" ? "15px" : "",
        }}
      >
        Books
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setSpSelectedOpt("articles");
          history.push("/spcontent/articles");
        }}
        style={{
          fontWeight: spSelectedOpt === "articles" ? "700" : "400",
          background: spSelectedOpt === "articles" ? "#344676" : "",
          borderRadius: spSelectedOpt === "articles" ? "15px" : "",
        }}
      >
        Articles
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setSpSelectedOpt("lifestyle");
          history.push("/spcontent/lifestyle");
        }}
        style={{
          fontWeight: spSelectedOpt === "lifestyle" ? "700" : "400",
          background: spSelectedOpt === "lifestyle" ? "#344676" : "",
          borderRadius: spSelectedOpt === "lifestyle" ? "15px" : "",
        }}
      >
        Lifestyle
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setSpSelectedOpt("gallery");
          history.push("/spcontent/gallery");
        }}
        style={{
          fontWeight: spSelectedOpt === "gallery" ? "700" : "400",
          background: spSelectedOpt === "gallery" ? "#344676" : "",
          borderRadius: spSelectedOpt === "gallery" ? "15px" : "",
        }}
      >
        Gallery
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setSpSelectedOpt("interviews");
          history.push("/spcontent/interviews");
        }}
        style={{
          fontWeight: spSelectedOpt === "interviews" ? "700" : "400",
          background: spSelectedOpt === "interviews" ? "#344676" : "",
          borderRadius: spSelectedOpt === "interviews" ? "15px" : "",
        }}
      >
        Interviews
      </div>
    </div>
  );
};

export default SPSubOptions;
