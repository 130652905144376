import React from "react";
import "./homepage.scss";
import logo1 from "../../Image/sp-logo1.svg";
import nvestlogo from "../../Image/nvest-logo.svg";
import connect from "../../Image/connect.svg";
import entrepreneur from "../../Image/img1.svg";
import entrepreneur1 from "../../Image/photo1.png";
import speaker from "../../Image/img2.svg";
import speaker1 from "../../Image/photo2.png";
import investor from "../../Image/img3.svg";
import investor1 from "../../Image/photo3.png";
import arrow from "../../Image/r-arrow.svg";
import insta from "../../Image/insta.svg";
import utube from "../../Image/utube.svg";
import twitter from "../../Image/twitter.svg";
import medium from "../../Image/medium.svg";
import fb from "../../Image/fb.svg";
import telegram from "../../Image/telegram.svg";
import linkedIn from "../../Image/linkedIn.svg";
import snapchat from "../../Image/snapchat.svg";
import gmail from "../../Image/gmail.svg";
import discord from "../../Image/discord.svg";
import whatsapp from "../../Image/whatsapp.svg";
import { useHistory } from "react-router-dom";
import { useMenuContext } from "../../Context/MenuContext";
import useWindowDimensions from "../../Utils/WindowSize";
import Sidebar from "../Sidebar/Sidebar";
import { useEffect } from "react";
import MobileNavbar from "../MobileNavbar";

const Homepage = () => {
  const {
    storySelectedOpt,
    setStorySelectedOpt,
    ventureSelectedOpt,
    setVentureSelectedOpt,
    gxselectedBtn,
    setGxselectedBtn,
    menu1,
    setMenu1,
    menu2,
    setMenu2,
    menu3,
    setMenu3,
    menu4,
    setMenu4,
    active,
    setActive,
    mobileSidebar,
    setMobileSidebar,
    setMobileMenu,
  } = useMenuContext();

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    setMenu1(false);
    setMenu2(false);
    setMenu3(false);
    setMenu4(false);
    setActive(false);
  }, []);

  return (
    <div className="wrapper">
      <Sidebar />
      <MobileNavbar />
      <div
        className="overlay"
        style={{ display: mobileSidebar ? "block" : "none" }}
        onClick={() => {
          setMobileSidebar(false);
          setMobileMenu("story");
        }}
      ></div>
      <div className="right-div">
        <div className="cover-img"></div>
        <div className="name-details">
          <div className="name">
            <p className="font1" style={{ marginBottom: "0" }}>
              HEY I'M
            </p>
            <p className="font2" style={{ marginBottom: "0" }}>
              SHORUPAN
            </p>
            <div className="ceo-text">
              <p style={{ marginBottom: "0" }}>
                {width <= 900 ? "Founder of" : "CEO & Founder Of"}
              </p>
              <img
                src={nvestlogo}
                alt=""
                className="nvest-logo"
                onClick={() => window.open("https://nvest.group/", "_blank")}
              />
            </div>
          </div>
          <div className="buttons">
            <label className="label1">
              <img src={connect} alt="" />
              Connect
            </label>
            <label className="label2">
              {width <= 900 ? "Learn more" : "Learn About me"}
            </label>
          </div>
        </div>
        <div className="headerImage">
          <div className="images">
            <img src={width > 900 ? entrepreneur : entrepreneur1} alt="" />
            <div className="overlay"></div>
            <div className="overlayText">Entrepreneur</div>
          </div>
          <div className="images">
            <img src={width > 900 ? speaker : speaker1} alt="" />
            <div className="overlay"></div>
            <div className="overlayText">Speaker</div>
          </div>
          <div className="images">
            <img src={width > 900 ? investor : investor1} alt="" />
            <div className="overlay"></div>
            <div className="overlayText">Investor</div>
          </div>
        </div>
        {width > 900 ? (
          <div className="connect-section">
            <div className="text-div">
              <div className="text">
                <div>See What I Am Up To</div>
                <img src={arrow} alt="" />
              </div>
              <div className="text">
                <div>Connect Direct To Me</div>
                <img src={arrow} alt="" />
              </div>
            </div>
            <div className="icons-div">
              <div className="icons">
                <img src={insta} alt="" />
                <img src={utube} alt="" />
                <img src={twitter} alt="" />
                <img src={medium} alt="" />
              </div>
              <div className="icons1">
                <div className="flex-div">
                  <img src={fb} alt="" />
                  <img src={telegram} alt="" />
                  <img src={linkedIn} alt="" />
                  <img src={snapchat} alt="" />
                </div>
                <div className="flex-div">
                  <img src={gmail} alt="" />
                  <img src={discord} alt="" />
                  <img src={whatsapp} alt="" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mob-connect-section">
            <div className="connect-txt">Connect Directly To Me</div>
            <div className="mob-icons">
              <div className="border-div">
                <img src={insta} alt="" />
              </div>
              <div className="border-div">
                <img src={utube} alt="" />
              </div>
              <div className="border-div">
                <img src={twitter} alt="" />
              </div>
              <div className="border-div">
                <img src={fb} alt="" />
              </div>
              <div className="border-div">
                <img src={telegram} alt="" />
              </div>
              <div className="border-div">
                <img src={linkedIn} alt="" />
              </div>
              <div className="border-div">
                <img src={snapchat} alt="" />
              </div>
              <div className="border-div">
                <img src={whatsapp} alt="" />
              </div>
              <div className="border-div">
                <img src={gmail} alt="" />
              </div>
              <div className="border-div">
                <img src={discord} alt="" />
              </div>
              <div className="border-div">
                <img src={medium} alt="" />
              </div>
            </div>
          </div>
        )}
        <div className="footer-div">
          <div className="div1">
            <div className="footer-logo">
              <img src={logo1} alt="" />
            </div>
            <div className="footer-details">
              <div className="contact-details">
                <div className="txt">Contact</div>
                <div className="con-container">
                  <div className="cont-1">
                    <span>Email:</span> shorupan@gmail.com
                  </div>
                  <div className="cont-1">
                    <span>Phone:</span> +1-647-559-0183
                  </div>
                </div>
              </div>
              <div className="nav-details">
                <div className="txt">Navigation</div>
                <div className="flex-class">
                  <div className="marginTop">
                    <div className="cont-1">My story</div>
                    <div className="cont-1">Ventues</div>
                    <div className="cont-1">SP Content</div>
                  </div>
                  <div className="marginTop">
                    <div className="cont-1">Lets Partner</div>
                    <div className="cont-1">Invest With Me</div>
                    <div className="cont-1">Job Opportunities</div>
                    <div
                      className="cont-1"
                      style={{ display: width <= 900 ? "block" : "none" }}
                    >
                      Book Me
                    </div>
                  </div>
                  <div className="marginTop">
                    <div
                      className="cont-1"
                      style={{ marginTop: width <= 900 ? "-4rem" : "0" }}
                    >
                      Hire Me
                    </div>
                    <div
                      className="cont-1"
                      style={{ display: width < 900 ? "none" : "block" }}
                    >
                      Book Me
                    </div>
                    <div className="cont-1">Media Kit</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div2">
            <div style={{ width: "80%", letterSpacing: "1px" }}>
              All Rights Reserved 2022. Shorupan TechPro PVT Ltd.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
