import React from "react";
import { useHistory } from "react-router-dom";
import { useMenuContext } from "../../Context/MenuContext";

const StorySuboptions = () => {
  const { storySelectedOpt, setStorySelectedOpt } = useMenuContext();
  const history = useHistory();

  

  return (
    <div className="sub-options">
      <div
        className="sub-opt"
        onClick={() => {
          setStorySelectedOpt("upbringing");
          history.push('/story/upbringing');
        }}
        style={{
          fontWeight: storySelectedOpt === "upbringing" ? "700" : "400",
          background: storySelectedOpt === "upbringing" ? "#344676" : "",
          borderRadius: storySelectedOpt === "upbringing" ? "15px" : "",
        }}
      >
        Upbringing
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setStorySelectedOpt("precrypto");
          history.push('/story/precrypto');
        }}
        style={{
          fontWeight: storySelectedOpt === "precrypto" ? "700" : "400",
          background: storySelectedOpt === "precrypto" ? "#344676" : "",
          borderRadius: storySelectedOpt === "precrypto" ? "15px" : "",
        }}
      >
        Pre-Crypto
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setStorySelectedOpt("nvestera");
          history.push('/story/nvestera');
        }}
        style={{
          fontWeight: storySelectedOpt === "nvestera" ? "700" : "400",
          background: storySelectedOpt === "nvestera" ? "#344676" : "",
          borderRadius: storySelectedOpt === "nvestera" ? "15px" : "",
        }}
      >
        Nvest Era
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setStorySelectedOpt("vision");
          history.push('/story/vision');
        }}
        style={{
          fontWeight: storySelectedOpt === "vision" ? "700" : "400",
          background: storySelectedOpt === "vision" ? "#344676" : "",
          borderRadius: storySelectedOpt === "vision" ? "15px" : "",
        }}
      >
        Vision
      </div>
      <div
        className="sub-opt"
        onClick={() => {
          setStorySelectedOpt("philanthropy");
          history.push('/story/philanthropy');
        }}
        style={{
          fontWeight: storySelectedOpt === "philanthropy" ? "700" : "400",
          background: storySelectedOpt === "philanthropy" ? "#344676" : "",
          borderRadius: storySelectedOpt === "philanthropy" ? "15px" : "",
        }}
      >
        Philanthropy
      </div>
    </div>
  );
};

export default StorySuboptions;
