import React from "react";
import { Route, Switch, Redirect, useHistory, Router } from "react-router-dom";
import Homepage from "./comps/HomePage/Homepage";
import SPContent from "./comps/SPcontent";
import Story from "./comps/Story";
import Ventures from "./comps/Ventures";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Homepage />} />
      <Route exact path="/story" render={() => <Story />}> <Redirect to="/story/upbringing" /> </Route>
      <Route exact path="/story/:id" render={() => <Story />} />
      <Route exact path="/ventures" render={() => <Ventures />} />
      <Route exact path="/ventures/:id" render={() => <Ventures />} />
      <Route exact path="/spcontent" render={() => <SPContent />} />
      <Route exact path="/spcontent/:id" render={() => <SPContent />} />
    </Switch>
  );
};

export default Routes;
