import React from "react";
import { useHistory } from "react-router-dom";
import { useMenuContext } from "../../Context/MenuContext";
import useWindowDimensions from "../../Utils/WindowSize";
import hamburger from "../../Image/hamburger.svg";
import close from "../../Image/close.svg";
import logo1 from "../../Image/sp-logo1.svg";
import "../HomePage/homepage.scss";
import { PreventscrollMob } from "../utils/PreventScroll";

const MobileNavbar = () => {
  const {
    storySelectedOpt,
    setStorySelectedOpt,
    ventureSelectedOpt,
    setVentureSelectedOpt,
    gxselectedBtn,
    setGxselectedBtn,
    menu1,
    setMenu1,
    menu2,
    setMenu2,
    menu3,
    setMenu3,
    menu4,
    setMenu4,
    active,
    setActive,
    mobileSidebar,
    setMobileSidebar,
    mobileMenu,
    setMobileMenu,
    setSpSelectedOpt,
    setMarketverseSelectedBtn,
    setInrSelectedBtn,
  } = useMenuContext();

  const { width } = useWindowDimensions();
  const history = useHistory();

  // var marker = document.querySelector("#marker");
  // var item = document.querySelectorAll(".each-heading");

  // function indicator(e) {
  //   marker.style.left = e.offsetLeft + "px";
  //   marker.style.width = e.offsetWidth + "px";
  // }

  // item.forEach((el) => {
  //   el.addEventListener("click", (e) => {
  //     indicator(e.target);
  //   });
  // });

  return (
    <div
      className="mob-navbar"
      style={{ display: width <= 900 ? "flex" : "none" }}
    >
      <div className="hamburger-menu">
        <img
          src={mobileSidebar ? close : hamburger}
          alt=""
          onClick={() => {
            setMobileSidebar(!mobileSidebar);
          }}
        />
      </div>
      <div className="mob-logo">
        <img
          src={logo1}
          alt=""
          onClick={() => {
            history.push("/");
            setMenu1(false);
            setMenu2(false);
            setMenu3(false);
            setMenu4(false);
            setActive(false);
            setMobileSidebar(false);
            setStorySelectedOpt("upbringing");
            setVentureSelectedOpt("");
            setGxselectedBtn("brands");
            setMobileMenu("story");
            setSpSelectedOpt("speeches");
            setMarketverseSelectedBtn("protocols");
            setInrSelectedBtn("holdings");
          }}
        />
      </div>
      <div
        className="mob-sidebar"
        style={{
          top: mobileSidebar ? "60px" : "-1000%",
        }}
      >
        <div className="heading-section">
          {/* <div id="marker"></div> */}
          <div
            className="each-heading"
            style={{
              fontWeight: mobileMenu === "story" ? "700" : "400",
              borderBottom:
                mobileMenu === "story" ? "0.5px solid #182443" : "0px",
            }}
            onClick={() => {
              setMobileMenu("story");
              // setMobileSidebar(!mobileSidebar);
              // history.push("/story");
              PreventscrollMob();
            }}
          >
            Story
          </div>

          <div
            className="each-heading"
            style={{
              fontWeight: mobileMenu === "ventures" ? "700" : "400",
              borderBottom:
                mobileMenu === "ventures" ? "0.5px solid #182443" : "0px",
            }}
            onClick={() => {
              setMobileMenu("ventures");
              setMobileSidebar(!mobileSidebar);
              history.push("/ventures");
              PreventscrollMob();
            }}
          >
            Ventures
          </div>
          <div
            className="each-heading"
            style={{
              fontWeight: mobileMenu === "spcontent" ? "700" : "400",
              borderBottom:
                mobileMenu === "spcontent" ? "0.5px solid #182443" : "0px",
            }}
            onClick={() => {
              setMobileMenu("spcontent");
              setMobileSidebar(!mobileSidebar);
              history.push("/spcontent");
              PreventscrollMob();
            }}
          >
            SP Content
          </div>
        </div>
        <div
          className="sub-headings"
          style={{ top: mobileSidebar ? "15%" : "-100%" }}
        >
          <div className="text" id="scroll-options">
            {mobileMenu === "story" ? (
              <>
                <div
                  onClick={() => {
                    setMobileSidebar(!mobileSidebar);
                    history.push("/story");
                  }}
                >
                  Upbringing
                </div>
                <div
                  onClick={() => {
                    setMobileSidebar(!mobileSidebar);
                    history.push("/story");
                  }}
                >
                  Pre-Crypto
                </div>
                <div
                  onClick={() => {
                    setMobileSidebar(!mobileSidebar);
                    history.push("/story");
                  }}
                >
                  Nvest Era
                </div>
                <div
                  onClick={() => {
                    setMobileSidebar(!mobileSidebar);
                    history.push("/story");
                  }}
                >
                  Vision
                </div>
                <div
                  onClick={() => {
                    setMobileSidebar(!mobileSidebar);
                    history.push("/story");
                  }}
                >
                  Philanthropy
                </div>
              </>
            ) : mobileMenu === "ventures" ? (
              <>
                <div
                  onClick={() => {
                    setVentureSelectedOpt("gx");
                    history.push("/ventures/gx");
                    setMobileSidebar(!mobileSidebar);
                  }}
                >
                  GX
                </div>
                <div
                  onClick={() => {
                    setVentureSelectedOpt("marketsverse");
                    history.push("/ventures/marketsverse");
                    setMobileSidebar(!mobileSidebar);
                  }}
                >
                  MarketsVerse
                </div>
                <div
                  onClick={() => {
                    setVentureSelectedOpt("viral");
                    history.push("/ventures/viral");
                    setMobileSidebar(!mobileSidebar);
                  }}
                >
                  Viral
                </div>
                <div
                  onClick={() => {
                    setVentureSelectedOpt("inr");
                    history.push("/ventures/inr");
                    setMobileSidebar(!mobileSidebar);
                  }}
                >
                  INR
                </div>
                <div
                  onClick={() => {
                    setVentureSelectedOpt("employment");
                    history.push("/ventures/employment");
                    setMobileSidebar(!mobileSidebar);
                  }}
                >
                  Employment
                </div>
                <div
                  onClick={() => {
                    setVentureSelectedOpt("cryptostartups");
                    history.push("/ventures/cryptostartups");
                    setMobileSidebar(!mobileSidebar);
                  }}
                >
                  CryptoStartups
                </div>
                <div
                  onClick={() => {
                    setVentureSelectedOpt("spending");
                    history.push("/ventures/spending");
                    setMobileSidebar(!mobileSidebar);
                  }}
                >
                  Spending
                </div>
                <div>Business</div>
              </>
            ) : (
              <>
                <div
                  onClick={() => {
                    setSpSelectedOpt("speeches");
                  }}
                >
                  Speeches
                </div>
                <div
                  onClick={() => {
                    setSpSelectedOpt("shows");
                  }}
                >
                  Shows
                </div>
                <div
                  onClick={() => {
                    setSpSelectedOpt("books");
                  }}
                >
                  Books
                </div>
                <div
                  onClick={() => {
                    setSpSelectedOpt("articles");
                  }}
                >
                  Articles
                </div>
                <div
                  onClick={() => {
                    setSpSelectedOpt("lifestyle");
                  }}
                >
                  Lifestyle
                </div>
                <div
                  onClick={() => {
                    setSpSelectedOpt("gallery");
                  }}
                >
                  Gallery
                </div>
                <div
                  onClick={() => {
                    setSpSelectedOpt("interviews");
                  }}
                >
                  Interviews
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
