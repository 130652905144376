import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./listeners.scss";

const responsive = {
  200: { items: 1 },
  768: { items: 2 },
  1200: { items: 3 },
};

const items = [
  <div className="l-item" data-value="1">
    <div className="l-carousel__slide-item-title">
      <h4>Every episode is an opportunity!</h4>
    </div>
    <div className="l-carousel-slide-item__body">
      <p className="stars">⭐️ ⭐️ ⭐️ ⭐️ ⭐️</p>
      <p className="by-txt">BY GLORAYA</p>
      <p className="content">
        I love how Jay makes a point to always give something practical to take
        away from each episode. My favorite piece of advice is his bit about
        “single-tasking”. I’m a horrible multi-tasker, so that made me more
        aware of the quality of whatever I’m doing. I’ve been trying to be more
        deliberate and intentional by single-tasking, and it’s been a great
        challenge.
      </p>
    </div>
  </div>,
  <div className="l-item" data-value="2">
    <div className="l-carousel__slide-item-title">
      <h4>Super practical!</h4>
    </div>
    <div className="l-carousel-slide-item__body">
      <p className="stars">⭐️ ⭐️ ⭐️ ⭐️ ⭐️</p>
      <p className="by-txt">BY SUPER PRACTICAL</p>
      <p className="content">
        I love how Jay makes a point to always give something practical to take
        away from each episode. My favorite piece of advice is his bit about
        “single-tasking”. I’m a horrible multi-tasker, so that made me more
        aware of the quality of whatever I’m doing. I’ve been trying to be more
        deliberate and intentional by single-tasking, and it’s been a great
        challenge.
      </p>
    </div>
  </div>,
  <div className="l-item" data-value="3">
    <div className="l-carousel__slide-item-title">
      <h4>Meaningful content!!</h4>
    </div>
    <div className="l-carousel-slide-item__body">
      <p className="stars">⭐️ ⭐️ ⭐️ ⭐️ ⭐️</p>
      <p className="by-txt">BY MARMEDUKE PUKE</p>
      <p className="content">
        I love how Jay makes a point to always give something practical to take
        away from each episode. My favorite piece of advice is his bit about
        “single-tasking”. I’m a horrible multi-tasker, so that made me more
        aware of the quality of whatever I’m doing. I’ve been trying to be more
        deliberate and intentional by single-tasking, and it’s been a great
        challenge.
      </p>
    </div>
  </div>,
  <div className="l-item" data-value="4">
    <div className="l-carousel__slide-item-title">
      <h4>Mentor I hope to meet someday</h4>
    </div>
    <div className="l-carousel-slide-item__body">
      <p className="stars">⭐️ ⭐️ ⭐️ ⭐️ ⭐️</p>
      <p className="by-txt">BY HIDEF53</p>
      <p className="content">
        I love how Jay makes a point to always give something practical to take
        away from each episode. My favorite piece of advice is his bit about
        “single-tasking”. I’m a horrible multi-tasker, so that made me more
        aware of the quality of whatever I’m doing. I’ve been trying to be more
        deliberate and intentional by single-tasking, and it’s been a great
        challenge.
      </p>
    </div>
  </div>,
  <div className="l-item" data-value="5">
    <div className="l-carousel__slide-item-title">
      <h4>Inspiring</h4>
    </div>
    <div className="l-carousel-slide-item__body">
      <p className="stars">⭐️ ⭐️ ⭐️ ⭐️ ⭐️</p>
      <p className="by-txt">BY SHOCKED AND OFFENDED</p>
      <p className="content">
        I love how Jay makes a point to always give something practical to take
        away from each episode. My favorite piece of advice is his bit about
        “single-tasking”. I’m a horrible multi-tasker, so that made me more
        aware of the quality of whatever I’m doing. I’ve been trying to be more
        deliberate and intentional by single-tasking, and it’s been a great
        challenge.
      </p>
    </div>
  </div>,
  <div className="l-item" data-value="6">
    <div className="l-carousel__slide-item-title">
      <h4>The most Tranformational podcast</h4>
    </div>
    <div className="l-carousel-slide-item__body">
      <p className="stars">⭐️ ⭐️ ⭐️ ⭐️ ⭐️</p>
      <p className="by-txt">BY LD504</p>
      <p className="content">
        I love how Jay makes a point to always give something practical to take
        away from each episode. My favorite piece of advice is his bit about
        “single-tasking”. I’m a horrible multi-tasker, so that made me more
        aware of the quality of whatever I’m doing. I’ve been trying to be more
        deliberate and intentional by single-tasking, and it’s been a great
        challenge.
      </p>
    </div>
  </div>,
];

const ListenersCarousel = () => (
  <div className="l-div">
    <AliceCarousel
      mouseTracking
      items={items}
      responsive={responsive}
      controlsStrategy="alternate"
    />
  </div>
);

export default ListenersCarousel;
