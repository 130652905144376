import React from "react";
import "./App.css";
import { MenuContextProvider } from "./Context/MenuContext";
import Routes from "./Routes";
import "react-loading-skeleton/dist/skeleton.css";
function App() {
  const [toggle, settoggle] = React.useState(true);

  const handletoggle = () => {
    settoggle(false);
  };
  return (
    <>
      <MenuContextProvider>
        <Routes />
      </MenuContextProvider>
    </>
  );
}

export default App;
