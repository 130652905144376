import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./carousel.scss";
import pic from "./pic.jpg";
import pic1 from "./pic1.jpg";
import pic2 from "./pic2.jpg";
import pic3 from "./pic3.jpg";

const responsive = {
  0: { items: 1 },
  275: { items: 2 },
  768: { items: 3 },
  975: { items: 4 },
  1200: { items: 5 },
  1400: { items: 6 },
};

const items = [
  <div className="item" data-value="1">
    <div style={{ overflow: "hidden" }}>
      <img src={pic} alt="" className="image" />
    </div>
    <div className="content-area">
      <h4>NOV 22 2022</h4>
      <p>3 Myths About Work Life Balance & 5 Ways to Really Create It.</p>
    </div>
  </div>,
  <div className="item" data-value="2">
    <div style={{ overflow: "hidden" }}>
      <img src={pic1} alt="" className="image" />
    </div>
    <div className="content-area">
      <h4>NOV 22 2022</h4>
      <p>3 Myths About Work Life Balance & 5 Ways to Really Create It.</p>
    </div>
  </div>,
  <div className="item" data-value="3">
    <div style={{ overflow: "hidden" }}>
      <img src={pic2} alt="" className="image" />
    </div>
    <div className="content-area">
      <h4>NOV 22 2022</h4>
      <p>3 Myths About Work Life Balance & 5 Ways to Really Create It.</p>
    </div>
  </div>,
  <div className="item" data-value="4">
    <div style={{ overflow: "hidden" }}>
      <img src={pic3} alt="" className="image" />
    </div>
    <div className="content-area">
      <h4>NOV 22 2022</h4>
      <p>3 Myths About Work Life Balance & 5 Ways to Really Create It.</p>
    </div>
  </div>,
  <div className="item" data-value="5">
    <div style={{ overflow: "hidden" }}>
      <img src={pic} alt="" className="image" />
    </div>
    <div className="content-area">
      <h4>NOV 22 2022</h4>
      <p>3 Myths About Work Life Balance & 5 Ways to Really Create It.</p>
    </div>
  </div>,
  <div className="item" data-value="5">
    <div style={{ overflow: "hidden" }}>
      <img src={pic1} alt="" className="image" />
    </div>
    <div className="content-area">
      <h4>NOV 22 2022</h4>
      <p>3 Myths About Work Life Balance & 5 Ways to Really Create It.</p>
    </div>
  </div>,
  <div className="item" data-value="5">
    <div style={{ overflow: "hidden" }}>
      <img src={pic2} alt="" className="image" />
    </div>
    <div className="content-area">
      <h4>NOV 22 2022</h4>
      <p>3 Myths About Work Life Balance & 5 Ways to Really Create It.</p>
    </div>
  </div>,
  <div className="item" data-value="5">
    <div style={{ overflow: "hidden" }}>
      <img src={pic3} alt="" className="image" />
    </div>
    <div className="content-area">
      <h4>NOV 22 2022</h4>
      <p>3 Myths About Work Life Balance & 5 Ways to Really Create It.</p>
    </div>
  </div>,
];

const Carousel = () => (
  <div className="div">
    <AliceCarousel
      mouseTracking
      items={items}
      responsive={responsive}
      controlsStrategy="alternate"
    />
  </div>
);

export default Carousel;
