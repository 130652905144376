import axios from "axios";

export const GetData = async (object) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.com/gxb/app/gxlive/operator/brands/get?parent_group_id=${object}`,
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};